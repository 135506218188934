<template>
	<el-row :gutter="160">
		<el-col :span="24" style="text-align: left;">
            <el-dropdown @command="onSelectGroup">
                <el-button type="success" style="width:144px;">
                    {{curGroupLabel}}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in onlinegroups" :key="item.value" :command="item"
                        icon="el-icon-coin">
                        {{item.label}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
			<el-button type="primary" icon="el-icon-plus" @click="onClickAdd" style="margin-left: 8px;">添加新配置</el-button>
            <el-button type="primary" icon="el-icon-brush" @click="onClickBatMdf" :disabled="noneSelectedRow">批量改参数</el-button>
            <el-button type="success" icon="el-icon-money" @click="onClickGroupMgr" style="margin-left: 8px;">分组管理</el-button>
		</el-col>
		<el-col :span="24">
			<el-table ref="multipleTable" :data="paramList" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
			:cell-style="{padding:4+'px'}" @selection-change="handleSelectionChange" class="serverlist">
				<el-table-column prop="id" type="selection" label="id" width="60"> </el-table-column>
				<el-table-column prop="name" label="配置表名称"></el-table-column>
                <el-table-column label="操作1" width="110">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEditParam(scope.row)" type="primary" icon="el-icon-edit" size="small" :disabled="isInBatMode">修改</el-button>
                    </template>
                </el-table-column>     
				<el-table-column prop="desc" label="描述"></el-table-column>
				<el-table-column prop="ver" label="版本"></el-table-column>
				<el-table-column prop="update_time" label="更新时间"></el-table-column>
				<el-table-column prop="create_time" label="添加时间"></el-table-column>
                <el-table-column prop="autover" label="versioncode" width="100"></el-table-column>
                <el-table-column label="操作2" width="200">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickDelParam(scope.row)" type="danger" icon="el-icon-delete" size="small">删除</el-button>
                    </template>
                </el-table-column>
			</el-table>
		</el-col>
		<!-- ========弹框BEGIN====== -->
		<el-dialog :title="formTitle" :visible.sync="dialogForm" width="40%" :close-on-click-modal="false" center class="formparams">
			<el-form :model="form">
                <el-form-item label="配置名称" :label-width="formLabelWidth" v-if="!isInBatMode">
                    <el-input v-model="form.name" autocomplete="off" @change="onFormEdited"></el-input>
                </el-form-item>
                <el-form-item label="版本" :label-width="formLabelWidth" v-if="!isInBatMode">
                    <el-input v-model="form.ver" autocomplete="off" @change="onFormEdited"></el-input>
                </el-form-item>
                <el-form-item label="描述" :label-width="formLabelWidth" v-if="!isInBatMode">
                    <el-input v-model="form.desc" autocomplete="off" @change="onFormEdited"></el-input>
                </el-form-item> 
                <el-form-item label="json配置内容" :label-width="formLabelWidth">
                    <el-input v-model="form.content" autocomplete="off" type="textarea" :rows="12" @change="onFormEdited"></el-input>          
                </el-form-item>   
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-check" @click="onAddOnlineParam" :disabled="noneChanged">确 定</el-button>
				<el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- ========弹框END====== -->
        <!-- ========分组管理弹框BEGIN====== -->
        <el-dialog title="分组管理" :visible.sync="dialogFormGroupMgr" :close-on-click-modal="false" width="900px" center>
            <el-button type="primary" icon="el-icon-plus" @click="onClickNewGroup">新建分组</el-button>
            <el-table :data="tableGroupData" height="410" border style="width: 100%" :row-class-name="tableRowClassName"
                :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="id号" width="60"> </el-table-column>
                <el-table-column prop="name" label="组名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="needtip" label="弹修改提示" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>{{scope.row.needtip?"是":"否"}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="tiptext" label="提示信息" show-overflow-tooltip></el-table-column>
                <el-table-column prop="amount" label="数量" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEditGroup(scope.row)" type="primary"
                            icon="el-icon-edit" size="small">编辑</el-button>
                        <el-button @click.native.prevent="onClickDelGroup(scope.row)" type="danger"
                            icon="el-icon-delete" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="分组名称" :visible.sync="dialogFormGroupEdit" :close-on-click-modal="false" width="700px" center>
            <el-form :model="formGroup">
                <el-form-item label="分组名称" :label-width="formLabelWidth">
                    <el-input v-model="formGroup.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="修改时弹提示" :label-width="formLabelWidth">
                <el-switch v-model="formGroup.needtip"  active-color="#13ce66" inactive-color="#969696"></el-switch>
                </el-form-item>                 
                <el-form-item label="提示的文字" :label-width="formLabelWidth">
                    <el-input v-model="formGroup.tiptext" placeholder="提示文字"></el-input>
                </el-form-item> 
                <el-form-item label="选择在线参数" :label-width="formLabelWidth">
                    <el-button type="primary" icon="el-icon-menu" @click="onSelectItem">在线参数</el-button>                   
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onCommitUpdateGroup">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogFormGroupEdit = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========分组管理弹框END====== -->
		<!-- ========物品弹框====== -->
        <el-dialog title="在线参数选择" :visible.sync="dialogItemForm" width="70%" :close-on-click-modal="false" :show-close="false" center class="itemdialog">
        <div style="width: 100%">
            <el-row>
              <el-col :span="10">
                  <el-input v-model="search" placeholder="关键字搜索" style="width:100%;"></el-input>
              </el-col>              
              <el-col :span="4">
                  <el-button type="success" plain style="width:100%;" disabled>&gt;&gt;&gt;&gt;&gt;&gt;</el-button>
              </el-col>
              <el-col :span="10">
                  <el-button type="primary" style="width:100%;" disabled>已选择的在线参数</el-button>
              </el-col>
            </el-row>
            <el-row :gutter="0">
              <el-col :span="10">
                  <el-table :data="filterItems" border style="width: 100%" height="420" class="serverlist" @selection-change="leftSelectionChange">
                    <el-table-column label="ID" prop="id" type="selection"></el-table-column>
                    <el-table-column label="配置表名称" prop="name" show-overflow-tooltip></el-table-column>
                    <el-table-column label="描述" prop="desc" show-overflow-tooltip></el-table-column>                              
                    <el-table-column label="操作" >
                      <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="onAddItem([scope.row])">添加</el-button>
                      </template>
                    </el-table-column>
                  </el-table>               
              </el-col>
              <el-col :span="4">
                  <el-row>
                      <el-button size="mini" type="primary" @click="onAddItem(null)" style="margin-top:180px;">批量&gt;&gt;添加</el-button>
                  </el-row>
                  <el-row>
                      <el-button size="mini" type="danger" @click="onRemoveItem(null)">批量&lt;&lt;移除</el-button>
                  </el-row>
              </el-col>
              <el-col :span="10">
                  <el-table :data="selectedParams" border style="width: 100%" height="420" class="serverlist" @selection-change="rightSelectionChange">
                    <el-table-column label="ID" prop="id" type="selection" ></el-table-column>
                    <el-table-column label="配置名" prop="name" show-overflow-tooltip></el-table-column>
                    <el-table-column label="描述" prop="desc" show-overflow-tooltip></el-table-column>                    
                    <el-table-column label="操作">
                      <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="onRemoveItem([scope.row])" style="float:right;">移除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>                
              </el-col>
            </el-row>            
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" icon="el-icon-check" @click="dialogItemForm=false">确 定</el-button>
        </div>
        </el-dialog>
		<!-- ========弹框END====== -->
	</el-row>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'

	export default {
		props: ['pkgtype'],
		data() {
			return {
				paramList: [],
                formTitle: '',
				dialogForm: false,
                form: {
                    id: '',
                    name: '',
                    ver: '',
                    desc: '',
                    content: '',
                    autover: '',
                    groupid: ''
                },
				noneChanged: true,
                selectedRows:[], // 批量功能:选择的行
                noneSelectedRow: true, // 批量功能:标记未选择行(按钮状态)
                ///////////////
                dialogFormGroupMgr: false,
                dialogFormGroupEdit: false,
                tableGroupData: [],
                onlinegroups: [],
                curGroupId: -1, // 当前选择分组(默认全部)
                curGroupLabel: "全部分组", // 当前选择分组名
                formGroup: {
                    name: '',
                    needtip: false,
                    tiptext: '',
                },
                search: '', // 列表选择项过滤关键字
                dialogItemForm: false, // 列表项选择
                waitparams: [], // 可选择在线参数
                selectedParams: [], // 已选择的在线参数
                leftSelectRows: [], // 左边选择的项
                rightSelectRows: [], // 右边选择的项
                ///////////////
                formLabelWidth: '130px',
                tableHeight: document.documentElement.clientHeight-136,
			}
		},
		created: function() {
            this.refreshGroupList();
			this.refreshOnlineParamList();
		},
		computed: {
			...mapGetters(
				['gameHttp']
			),
            // 新增或编辑区服的分组时，使用可选择的项目
            selectGroups() {
                return this.onlinegroups.slice(1)
            },
            // 批量模式
            isInBatMode() {
                return this.selectedRows.length > 0;
            },
            // 在线参数选择关键词过滤显示
            filterItems() {
                return this.waitparams.filter((data)=>{
                    // 已选择过的则不用再显示了
                    for (let i=0; i<this.selectedParams.length; i++) {
                        if (data.id == this.selectedParams[i].id) {
                            return false;
                        }
                    }
                    // 没有搜索条件 或 包含搜索字则显示
                    return !this.search || (data.name.includes(this.search) || data.desc.includes(this.search));
                })
            },
		},
		methods: {
			// 刷新列表
			refreshOnlineParamList() {
				this.gameHttp(2003, {groupid: this.curGroupId}).then((response) => {
					this.paramList = response.data.list
				})
			},
			// 弹出新增参数编辑框
			onClickAdd() {
                this.formTitle = '添加在线参数'
				this.dialogForm = true
                this.noneChanged = true
                for (let k in this.form) {
                    this.form[k] = ''
                }
			},
            // 弹出修改参数编辑框
            onClickEditParam(row) {
                this.formTitle = '修改在线参数'
				this.dialogForm = true
                this.noneChanged = true 
                this.form = JSON.parse(JSON.stringify(row))
            },
			// 批量修改参数
			onClickBatMdf() {
				this.dialogForm = true;
                this.formTitle = '批量修改参数';
                this.noneChanged = true;
                this.form.content = "";
			},
			handleSelectionChange(val) {
				this.selectedRows = []
				for (let i = 0; i < val.length; i++) {
					this.selectedRows.push({id:val[i].id,name:val[i].name})
				}
				this.noneSelectedRow = (0 == val.length)? true : false
			},
            // 弹出删除确认框
            onClickDelParam(row) {
                this.$confirm('是否删除[ '+row.name+' ]在线参数?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(2005, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("删除成功")
                            this.refreshOnlineParamList()
                        }
                    })
                }).catch(() => {})
            },            
            // 编辑框内容变化事件
            onFormEdited() {
                this.noneChanged = false
            },
			// 添加在线配置
			onAddOnlineParam() {
				if (0==this.form.name.length && !this.isInBatMode) {
                    this.$message.error('配置名称不能为空'); return;
                }
                try {
                    JSON.parse(this.form.content)
                } catch(e) {
                    this.$message.error('json配置内容有误: '+e.toString()); return;                    
                }
                // 检查是否需要提示修改
                let check = false; let tip = "";
                for (let i=0; i<this.tableGroupData.length; i++) {
                    let item = this.tableGroupData[i];
                    if (item.id==Number(this.form.groupid) && 0!=item.needtip) {
                        check = true; tip = item.tiptext; break;
                    }
                }
                if (check && !this.isInBatMode) {
                    this.$confirm('是否提交对[ ' + this.form.name + ' ]在线参数的修改?\n['+tip+"]", '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.gameHttp(2004, this.form).then((response) => {// 请求操作
                            response.data.errcode==0?this.$message.success(response.data.errmsg):this.$message.error(response.data.errmsg)
                            this.refreshOnlineParamList()
                        });
                        this.dialogForm = false;
                    }).catch(() => {})
                } else {
                    if (this.isInBatMode) {
                        this.form.batrows = this.selectedRows;
                    }
                    this.gameHttp(2004, this.form).then((response) => {// 请求操作
                        response.data.errcode==0?this.$message.success(response.data.errmsg):this.$message.error(response.data.errmsg)
                        this.refreshOnlineParamList()
                    });
                    this.dialogForm = false;
                }
			},
            // 分组的新增/更新
            onClickNewGroup() {
                this.dialogFormGroupEdit = true
                this.formGroup.id = ''
                this.formGroup.name = ''
                this.formGroup.needtip = false;
                this.formGroup.tiptext = '';
                this.selectedParams = [];
            },
            onClickEditGroup(row) {
                this.dialogFormGroupEdit = true
                this.formGroup = JSON.parse(JSON.stringify(row));
                this.formGroup.needtip = 0==row.needtip?false:true;
                this.selectedParams = JSON.parse(JSON.stringify(row.params));
            },
            onClickGroupMgr() {
                this.dialogFormGroupMgr = true
                this.refreshGroupList()
            },
            // 分组的删除
            onClickDelGroup(row) {
                this.$confirm('是否删除[ ' + row.name + ' ]分组?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(2303, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("删除成功")
                            this.refreshGroupList()
                        }
                    })
                }).catch(() => {})
            },
            // 提交新增/编辑分组
            onCommitUpdateGroup() {
                if (0 == this.formGroup.name.length) {
                    this.$message.error('请输入分组名称！');
                    return;
                }
                if (this.formGroup.needtip && 0==this.formGroup.tiptext.length) {
                    this.$message.error('请输入修改时，弹出的提示文字！');
                    return;
                }
                this.formGroup.paramids = [];
                for (let i=0; i<this.selectedParams.length; i++) {
                    this.formGroup.paramids.push( this.selectedParams[i].id );
                }
                this.dialogFormGroupEdit = false
                // 请求操作
                this.gameHttp(2302, this.formGroup).then((response) => {
                    this.$message.success(response.data.errmsg)
                    this.refreshGroupList();
                    this.refreshOnlineParamList(); // 顺带要刷新列表，因为分组有改动，groupid有变
                })
            },
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2301, {}).then((response) => {
                    this.tableGroupData = response.data.list
                    // 更新分组的下拉列表
                    this.onlinegroups = [{
                        value: -1,
                        label: "全部分组"
                    }]
                    for (let i = 0; i < this.tableGroupData.length; i++) {
                        let item = this.tableGroupData[i]
                        this.onlinegroups.push({
                            value: item.id,
                            label: item.name
                        })
                    }
                })
            },
            // 选择在线参数分组
            onSelectGroup(item) {
                console.log("onSelectGroup=>", item)
                this.curGroupLabel = item.label
                this.curGroupId = item.value
                // 刷新选择结果
                this.refreshOnlineParamList()
            },
            // 弹出选择在线参数列表项
            onSelectItem() {
                this.dialogItemForm = true
				this.gameHttp(2003, {groupid: -1}).then((response) => {
					this.waitparams = response.data.list
				})
            },
            // 处理左边多选变化
			leftSelectionChange(rows) {
                this.leftSelectRows = rows;
			},
            // 处理右边多选变化
			rightSelectionChange(rows) {
                this.rightSelectRows = rows;
			},            
            // 添加项到选择列
            onAddItem(rows) {
                if (!rows) {
                    rows = this.leftSelectRows;
                }
                for (let i=0; i<rows.length; i++) {
                    let item = JSON.parse( JSON.stringify(rows[i]) );
                    this.selectedParams.push(item);
                }
            },
            // 移除选择的项
            onRemoveItem(rows) {
                if (!rows) {
                    rows = this.rightSelectRows;
                }
                let funcRomove = (row) => {
                    for (let i=0; i<this.selectedParams.length; i++) {
                        if (row.id == this.selectedParams[i].id) {
                            this.selectedParams.splice(i, 1);
                            // 左边列表不存在的则压入到左边
                            for (let j=0; j<this.waitparams.length; j++) {
                                if (row.id == this.waitparams[j].id) {
                                    return;
                                }
                            }
                            this.waitparams.push(row);
                            break;
                        }
                    } 
                };
                // 判断默认区服不能移除
                for (let r=0; r<rows.length; r++) {
                    if(rows[r].default) {
                        this.$message.error("不可移除默认区服:"+rows[r].name);
                        return;
                    }
                }
                for (let r=0; r<rows.length; r++) {
                    funcRomove(rows[r]);
                }
            },
			// 数据表色彩间隔效果     
			tableRowClassName({
				rowIndex
			}) {
				if (0 == rowIndex % 2) {
					return 'warning-row';
				} else {
					return 'success-row';
				}
			},
		},
	}
</script>

<style>
	.serverlist.el-table .warning-row {
		background: #f5f7fa;
	}

	.serverlist.el-table .success-row {
		background: #ffffff;
	}

	.serverlist.el-table {
		margin-top: 16px;
	}

	.serverlist {
		font-size: 12px;
	} 
</style>
